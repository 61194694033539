import React from 'react'

const Navbar = () => {
  return (
    <nav className=' flex w-full justify-between py-16'>
        <section className='flex justify-between w-full items-center'>
            <figure className='block'>
                {/* <img src={logo} alt='Logo' className=' block sm:w-28 w-28  md:w-28 lg:w-28 select-none'/> */}
            </figure>
                
            <figcaption >
              {/* <a href='#documentation'>Documentation (Soon)</a> */}
            </figcaption>
        </section>
    </nav>
  )
}

export default Navbar