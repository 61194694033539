import React from 'react'

const HomeBottom = () => {
  return (
    <div className='h-full w-full space-y-6'>
        <h1 className='text-2xl md:text-4xl font-bold'>
            Get Beta Access
        </h1>
        <p className=' text-lg md:text-xl font-extralight'>
            Our waitlist allows us to prioritize our early users and community at a launch 
            with unique features and early access
        </p>
        <button className='border rounded-md p-2 md:p-4 bg-white text-black' onClick={()=>window.location.href='https://zlfpnfilpjz.typeform.com/to/lHh5l8GF'}>
            Request Beta
        </button>
        
    </div>
  )
}

export default HomeBottom
