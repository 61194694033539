import React from 'react'

const HomeTop = () => {
  return (
    <div className='flex w-full h-1/2 md:h-2/3  gap-y-6 '>
        <div className='hidden md:flex w-1/2 flex-col justify-center  gap-y-10 '>
          <img src={require('../assets/images/logo.webp')} className=' w-5/6 ' alt='logo'/>
          <div className='gap-y-4 flex flex-col'>
            <div className='p-9'></div>
            <div className='p-9   '></div>
          </div>
        </div>
        <div className='flex flex-col w-full md:w-1/2 gap-y-10 items-center justify-center '>
            <h1 className='text-3xl md:text-5xl font-bold text-left md:text-right '>
                A Unified Cross-Chain Digital Identity
            </h1>
            <div className='flex items-start md:items-end flex-col gap-y-4 self-start md:self-end'>
              <p className='text-xl md:text-2xl font-thin md:text-right'>Beyond Chains, One Identity</p>
              <div className='flex-row space-x-4'>
                  <button className='border rounded-md p-2 md:p-4 bg-white text-black' onClick={()=>window.location.href='https://zlfpnfilpjz.typeform.com/to/lHh5l8GF'}>Request Beta</button>
                  <button className='border rounded-md p-2 md:p-4'n>Try Dapp</button>
              </div>
            </div>

        </div>
    </div>
  )
}

export default HomeTop
