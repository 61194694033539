import './App.css';
import { Homepage } from './pages';

function App() {
  return (
    <div className="flex items-center justify-center bg-PRIMARY font-Avenir-Regular">
      <Homepage/>
    </div>
  );
}

export default App;
