import React from 'react'

const HomeMid = () => {
  return (
    <div className='flex w-full h-2/3 md:h-1/3 justify-center'>
        <p className='flex flex-col text-center text-xl space-y-8 font-extralight px-0 md:px-32'>
            <div>
              <span className='font-black'>xGNOS</span> is a unified cross-chain digital identity offering <span className='font-black'>a cross-chain name </span>
              <span className='font-black'>service</span> built upon Wormhole, similar to the Token Attestation model. 
            </div>
            <div >

              This allows seamless recognition and use of credentials from one chain to
              another, enhancing experiences on platforms like DeFi and social networks,
              and ensuring secure, reliable cross-chain identity integration.
            </div>
        </p>
    </div>
  )
}

export default HomeMid