// import React from 'react'
// import { HomeTop, HomeMid, HomeBottom } from '../components'

// const Homepage = () => {
//   return (
//     <div className='flex h-full flex-col text-white mx-auto items-center w-full'>
//         <div className='h-screen max-w-7xl relative'>
//         <video
//           autoPlay
//           loop
//           muted
//           style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
//         >
//           <source src={require('../assets/images/XGNOS-hero-bg.mp4')} type="video/mp4" />
//           Your browser does not support the video tag.
//         </video>
//           <div className='h-full w-full'>

//             <HomeTop/>
//             <HomeMid/>
//           </div>
//         </div>
//         <div className='flex max-w-7xl items-center '>
//           <HomeBottom/>
//         </div>
//     </div>
//   )
// }

// export default Homepage

import React from 'react';
import { HomeTop, HomeMid, HomeBottom } from '../components';

const Homepage = () => {
  return (
    <div className='flex h-full flex-col text-white mx-auto items-center w-full'>
      <div className='h-screen w-full flex flex-col items-center relative'>
        <video
          autoPlay
          loop
          muted
          style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover',  }}
        >
          <source src={require('../assets/images/XGNOS-hero-bg.mp4')} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className='h-full w-full relative max-w-5xl px-6'>
          <HomeTop />
          <HomeMid />
        </div>
      </div>
      <div className='flex max-w-7xl items-center mb-16 px-6'>
        <HomeBottom />
      </div>
    </div>
  );
};

export default Homepage;
